(function($) {
    $.each(['show', 'hide'], function(i, ev) {
        var el = $.fn[ev];
        $.fn[ev] = function() {
            this.trigger(ev);
            return el.apply(this, arguments);
        };
    });
})(jQuery);


function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

function stopVideo(element) {
	var iframe = element.querySelector('iframe');
	var video = element.querySelector('video');
	if (iframe) {
		var iframeSrc = iframe.src;
		iframe.src = iframeSrc;
	}
	if ( video ) {
		video.pause();
	}
}

function videoDimensions(video) {
    // Ratio of the video's intrisic dimensions
    var videoRatio = video.videoWidth / video.videoHeight;
    // The width and height of the video element
    var width = video.offsetWidth, height = video.offsetHeight;
    // The ratio of the element's width to its height
    var elementRatio = width/height;
    // If the video element is short and wide
    if(elementRatio > videoRatio) width = height * videoRatio;
    // It must be tall and thin, or exactly equal to the original ratio
    else height = width / videoRatio;
    return {
        width: width,
        height: height
    };
}

function ditherVideo(container, captureframerate, playbackframerate, ditheringtype, threshold) {

	var cachedWidth = $(window).width();
	var newWidth;

    var sourceVideo = $(container).find('.source-video')[0];
    var sourceVideoWidth = 0;
    var sourceVideoHeight = 0;

    var sourceVideoCanvas;
    var sourceVideoCanvasContext;
    var ditherVideoCanvas;
    var ditherVideoCanvasContext;
    var sourceVideoCanvasImgData;
    var sourceVideoCanvasImgDataDither;
    var videoDitherImgData = [];
    var videoDitherImgDataIndex = 1;
    var capturefps = captureframerate;
    var playbackfps = playbackframerate;
    var canceled;
    var ditherImageElem;

    var ditherWorker = new Worker('libs/image-monochrome-worker/image-monochrome-worker.js');

    ditherWorker.onmessage = function(event) {
        sourceVideoCanvasImgDataDither = event.data;
        if (sourceVideo.ended == false) {
            ditherVideoCanvasContext.putImageData(sourceVideoCanvasImgDataDither, 0, 0);
            videoDitherImgData.push(sourceVideoCanvasImgDataDither);
        } else if (/Mobi/i.test(navigator.userAgent) || /Android/i.test(navigator.userAgent)) {
        	ditherVideoCanvasContext.putImageData(sourceVideoCanvasImgDataDither, 0, 0);
        	ditherImageElemDataURL = ditherVideoCanvas.toDataURL();
        	$(ditherImageElem).attr('src', ditherImageElemDataURL);

        	sourceVideoCanvasTemp = $(sourceVideoCanvas).clone();
        	ditherVideoCanvasTemp = $(ditherVideoCanvas).clone();

        	$(sourceVideoCanvas).remove();
        	$(ditherVideoCanvas).remove();

        	ditherWorker.terminate();
        }
    };

    if (/Mobi/i.test(navigator.userAgent) || /Android/i.test(navigator.userAgent)) {

    	if (canceled == true) {
    		$(container).append(sourceVideoCanvasTemp);
    		$(container).append(ditherVideoCanvasTemp);
    	}

		sourceVideo = $(container).parent().find('.case-studies__study-link__image').find('img')[0];
		ditherImageElem = $(container).parent().find('.case-studies__study-link__image-dithered').find('img')[0];

    	sourceVideoWidth = $(sourceVideo).innerWidth();
        sourceVideoHeight = $(sourceVideo).innerHeight();

        sourceVideoCanvas = $(container).find('.source-video-canvas')[0];
        sourceVideoCanvasContext = sourceVideoCanvas.getContext('2d');
        ditherVideoCanvas = $(container).find('.dither-video-canvas')[0];
        ditherVideoCanvasContext = ditherVideoCanvas.getContext('2d');

        sourceVideoCanvas.width = sourceVideoWidth;
        sourceVideoCanvas.height = sourceVideoHeight;
        ditherVideoCanvas.width = sourceVideoWidth;
        ditherVideoCanvas.height = sourceVideoHeight;

        $(ditherVideoCanvas).css('display', 'none');

        sourceVideoCanvasContext.drawImage(sourceVideo, 0, 0, sourceVideoWidth, sourceVideoHeight);
        
        sourceVideoCanvasImgData = sourceVideoCanvasContext.getImageData(0, 0, sourceVideoWidth, sourceVideoHeight);

    	ditherWorker.postMessage({'imageData': sourceVideoCanvasImgData, 'threshold': threshold, 'type': ditheringtype});

    } else {

	    (function bindDitherVideo() {
	        canceled = false;

	        if (!sourceVideo.paused) {
	            
	            sourceVideoWidth = $(sourceVideo).innerWidth();
	            sourceVideoHeight = $(sourceVideo).innerHeight();

	            $(sourceVideo).attr('width', sourceVideoWidth);
	            $(sourceVideo).attr('height', sourceVideoHeight);

	            sourceVideoCanvas = $(container).find('.source-video-canvas')[0];
	            sourceVideoCanvasContext = sourceVideoCanvas.getContext('2d');
	            ditherVideoCanvas = $(container).find('.dither-video-canvas')[0];
	            ditherVideoCanvasContext = ditherVideoCanvas.getContext('2d');

	            sourceVideoCanvas.width = sourceVideoWidth;
	            sourceVideoCanvas.height = sourceVideoHeight;
	            ditherVideoCanvas.width = sourceVideoWidth;
	            ditherVideoCanvas.height = sourceVideoHeight;

	            var $this = sourceVideo;

	            (function videoCanvasLoop() {
	                if (canceled) {
	                    clearTimeout(videoCanvasLoop);
	                    return;
	                }

	                sourceVideoCanvasContext.drawImage($this, 0, 0, sourceVideoWidth, sourceVideoHeight);
	                
	                sourceVideoCanvasImgData = sourceVideoCanvasContext.getImageData(0, 0, sourceVideoWidth, sourceVideoHeight);

	                ditherWorker.postMessage({'imageData': sourceVideoCanvasImgData, 'threshold': threshold, 'type': ditheringtype});

	                if ($this.ended == false) {
	                    setTimeout(videoCanvasLoop, 1000 / capturefps);
	                } else {
	                    clearTimeout(videoCanvasLoop);
	                }
	            })();

	            $(sourceVideo).on('ended', function() {
	                (function videoDitherLoop() {
	                    if (canceled) {
	                        clearTimeout(videoDitherLoop);
	                        return;
	                    }

	                    ditherVideoCanvasContext.clearRect(0, 0, sourceVideoWidth, sourceVideoHeight);

	                    ditherVideoCanvasContext.putImageData(videoDitherImgData[videoDitherImgDataIndex-1], 0, 0);

	                    videoDitherImgDataIndex++;

	                    if (videoDitherImgDataIndex == videoDitherImgData.length) {
	                        videoDitherImgDataIndex = 1;
	                    }

	                    setTimeout(videoDitherLoop, 1000 / playbackfps);
	                })();
	            });
	        } else {
	        	setTimeout(bindDitherVideo, 100);
	        }
	    })();

    }

    $(window).on('resize', function() {
    	newWidth = $(window).width();

		if (cachedWidth != newWidth) {
			canceled = true;
	        ditherWorker.terminate();
	        videoDitherImgData = [];
	        videoDitherImgDataIndex = 1;
	        sourceVideo.currentTime = 0;
	        sourceVideo.play();
	        $(sourceVideo).attr('width', '');
	        $(sourceVideo).attr('height', '');
		}

		cachedWidth = newWidth;
    });
}

$(function() {

	/**** LOADING ****/
	var boot = new Audio('libs/audio/Apple-IIc-boot.ogg');
    boot.volume = 0.8;

	$(boot).on('playing', function() {
		if (this.currentTime == 0.133) {
			this.pause();
		}
 	});

	Pace.on('done', function() {
		if (window.location.hash.match("^#case-study")) {
			setTimeout(function() {
				$('.__pace-overlay').css('display','none');
				$('.case-study-modal').show();
				$('body').css('overflow', 'visible');
			}, 800);
		} else {
			bootPlay = setTimeout(function() {
					boot.play();
					clearTimeout(boot);
			}, 600);

			setTimeout(function() {
				$('body').css('overflow', 'visible');
				$('.__pace-overlay').css('display','none');
			}, 800);
		}

		
	});

	/**** HEADER LOGO ****/

	// meSpeak.loadConfig('libs/mespeak/mespeak_config.json');
	// meSpeak.loadVoice('libs/mespeak/en-us.json');
	// var meSpeakData;

	var brandSpeak = new Audio('libs/audio/eehnfluent-soehluueshens.ogg');
    brandSpeak.volume = 1;

	$('.main-header__logomark-outline').on({
	    mouseenter: function () {
	    	$('.main-header__logotype-full').show();
	    	$('.main-header__logomark-outline-spaz').css('visibility','hidden');
	    	brandSpeak.play();
	    },
	    mouseleave: function () {
	    	$('.main-header__logotype-full').hide();
	    	$('.main-header__logomark-outline-spaz').css('visibility','visible');
	    	brandSpeak.pause();
	    	brandSpeak.currentTime = 0;
	    }
	});

	var top = getRandomInt(-25, 25);
	var left = getRandomInt(-10, 40);

	function logomarkSpaz() {
		top = getRandomInt(-25, 25);
		left = getRandomInt(-10, 40);
		$('.main-header__logomark-outline-spaz').show().css('top',top+'px').css('left',left+'px');
		setTimeout(function() {
		 	$('.main-header__logomark-outline-spaz').hide();
		}, 60); 
	}

	setInterval(logomarkSpaz, 1800);

	// if (!(/Mobi/i.test(navigator.userAgent) || /Android/i.test(navigator.userAgent))) {
	// 	setInterval(logomarkSpaz, 1800);
	// }

	var introTextTypeitOptions = {
		strings: $('.main-header__intro-text__span span').html(),
		cursor: false,
		speed: 35,
		startDelay: 0,
		callback: function() {
			$('.main-header__intro-text__span-typeit').addClass('--complete');
		}
	};

	var introTextTypeitOptionsMobile = {
		strings: $('.main-header__intro-text__span span').html(),
		cursor: false,
		speed: 1,
		startDelay: 0,
		callback: function() {
			$('.main-header__intro-text__span-typeit').addClass('--complete');
		}
	};

	Pace.on('done', function() {
		if (/Mobi/i.test(navigator.userAgent) && /iPad|iPhone|iPod/.test(navigator.userAgent)) {
			$('.main-header__intro-text__span-typeit span').html($('.main-header__intro-text__span span').html());
			$('.main-header__intro-text__span-typeit').addClass('--complete');
		} else {
			setTimeout(function() {
				$('.main-header__intro-text__span-typeit span').typeIt(introTextTypeitOptions);
			}, 1600);
		}

		
	});	

	/**** RULER ****/

	if (!(/Mobi/i.test(navigator.userAgent) && /iPad|iPhone|iPod/.test(navigator.userAgent))) {
		$('body').ruler({
			showMousePos: false,
			showCrosshair: false,
		}); 
	}

	  

	/**** SECTION: CASE STUDIES ****/ 

	var sourceVideoCanvasTemp;
    var ditherVideoCanvasTemp;

	var cachedWidth = $(window).width();
	var newWidth;

	if (/Mobi/i.test(navigator.userAgent) || /Android/i.test(navigator.userAgent)) {
		$('.case-studies__study-link__image-dithered ').css('display', 'block');
	}

	$('.case-studies__study-link__video').each(function() {
    	ditherVideo($(this), 5, 5, 'atkinson', null); 
	});

    $(window).on('resize', function() {
    	newWidth = $(window).width();

    	if (newWidth != cachedWidth) {
    		$('.case-studies__study-link__video').each(function() {
            	ditherVideo($(this), 5, 5, 'atkinson', null); 
        	});
    	}

        cachedWidth = newWidth;
    });

	$('.case-studies__study-link__heading__title').on({
	    mouseenter: function () {
	    	// $(this).closest('.case-studies__study-link').find('.case-studies__study-link__image').addClass('--hover');
	    	$(this).closest('.case-studies__study-link').find('.case-studies__study-link__video').addClass('--hover');
	    	$(this).closest('.case-studies__study-link').find('.case-studies__study-link__heading__description').addClass('--hover');
	    },
	    mouseleave: function () {
			// $(this).closest('.case-studies__study-link').find('.case-studies__study-link__image').removeClass('--hover');
			$(this).closest('.case-studies__study-link').find('.case-studies__study-link__video').removeClass('--hover');
			$(this).closest('.case-studies__study-link').find('.case-studies__study-link__heading__description').removeClass('--hover');
	    }
	});

	/**** SECTION: CONTACT FORM ****/ 

	$('.contact_contact-form__input-group input[type="text"], .contact_contact-form__input-group input[type="email"], .contact_contact-form__input-group textarea').on('focusin', function() {
		$(this).siblings('label').addClass('--non-empty').addClass('--focus');
	});

	$('.contact_contact-form__input-group input[type="text"], .contact_contact-form__input-group input[type="email"], .contact_contact-form__input-group textarea').on('focusout', function() {
		$(this).siblings('label').removeClass('--focus');

		if ($.trim($(this).val()).length == 0) {
			$(this).siblings('label').removeClass('--non-empty');
		}
	});

	/**** SECTION: CASE STUDY MODAL ****/

	var emptyTop = 0;
	var emptyReduction = 0;
	var open = false;

	$(window).on('hashchange', function() {
		Pace.stop();
	});

	var caseStudyModalHide = function() {
		rrrrr.pause();
		rrrrr.currentTime = 0;

		$('.case-study-modal__case-study-frame').addClass('--case-study-modal-hide');
		setTimeout(function() {
			$('.__case-study').css('display', 'none');
			$('.case-study-modal__case-study-frame').css('display', 'none');
			$('.case-study-modal__case-study-frame_content__loader').css('display','block');
			if (open) {
				history.back();
				open = false;
			} else {
				window.history.pushState(null, window.title, window.location.href);
				window.location.hash = '';
			}
		}, 300);

		setTimeout(function() {
			$('.case-study-modal').hide();
			$('.case-study-modal__case-study-frame').removeClass('--case-study-modal-hide');
			$('.case-study-modal__case-study-frame').css('display', 'block');
		}, 800);
	};

	if ($('.case-study-modal').is(':visible')) {
		$('body').addClass('modal-open');
	}

	$('.case-studies__study-link__heading__title a').on('click', function(e) {		
		open = true;
		$('.case-study-modal').show();
		$($(this).attr('href')).css('display','block');
	});

	$('.case-study-modal').on('show', function() {
		$('.__case-study').css('display','none');
		$(window.location.hash).css('display','block');

		$('body').addClass('modal-open');

	}).on('hide', function () {
		$('body').removeClass('modal-open');
	});

	$(window).on('hashchange', function(e){
		if (window.location.hash.match("^#case-study")) {
			$('.case-study-modal').show();
		}
	});

	$(window).on('popstate', function() {
    	if ($('.case-study-modal').is(':visible')) {
    		$('.case-study-modal').hide();
    	}
 	});

	$('.case-study-modal').on('click', function(e) {
		if (e.target !== this) {
    		return;
		} else {
			caseStudyModalHide();
		}
	});

	$('.case-study-modal__case-study-frame__header__close-modal').on('mouseup', function(e) {
		e.preventDefault();
		caseStudyModalHide();
	});

	$('.case-study-modal__case-study-frame__header__close-modal').on('click', function(e) {
		e.preventDefault();
	});

	var rrrrr = new Audio('libs/audio/rrrrr.ogg');
    rrrrr.volume = 0.8;

	$(rrrrr).on('playing', function() {
		if (this.currentTime == 1.533) {
			this.pause();
			this.currentTime = 0;
		}
 	});

	$('.case-study-modal').on('show', function() {
		emptyTop = 0;
		emptyReduction = 0;
		$('.case-study-modal__case-study-frame_content__loader').css('display','block');
		$('.__case-study').addClass('--waiting');
		$('.__loader__empty').css('top', emptyTop+'%');

		rrrrr.play();

		var interval = setInterval(function() {
			emptyReduction = getRandomInt(4, 12);
			emptyTop = emptyTop + emptyReduction;

		 	if ((emptyTop <= 100) && (emptyTop > 0)) {
		 		$('.__loader__empty').css('top', emptyTop+'%');
		 	} else {
		 		if ($('.case-study-modal__case-study-frame').is(':visible')) {
		 			$('.__case-study').removeClass('--waiting');
		 			$('.case-study-modal__case-study-frame_content__loader').css('display','none');
		 		}
		 		clearInterval(interval);
		 	}
		}, 80);
	});

	$('.case-study-modal').on('hide', function() {
		$('.__case-study').removeClass('--waiting');

		$('.__case-study .case-study-video-container__video .__video-wrapper').each(function() {
			stopVideo($(this)[0]);
		});
	});

	$(".__case-study__carousel-slider").owlCarousel({
		items: 1,
		loop: true,
		autoplay: true,
		autoplayTimeout: 10000,
		stageOuterClass: 'owl-stage-outer col-md-12',
		controlsClass: 'owl-controls row',
		dotsClass: 'owl-dots col-md-3 col-md-push-9 col-md-offset-0 col-sm-10 col-sm-offset-1',
		dragEndSpeed: 300,
		autoplaySpeed: 1,
		dotsSpeed: 1,
		fallbackEasing: 'linear'
	});

	$('<div class="col-md-8 col-md-offset-1 col-md-pull-3 col-sm-10 col-sm-offset-1"><div class="__case-study__carousel-slider__slide__description">Image — <span></span></div></div>').appendTo('.__case-study__carousel-slider');

	$('.__case-study__carousel-slider').each(function() {
		var descriptionContainerParent = $(this).find('.__case-study__carousel-slider__slide__description');
		var descriptionContainer = $(this).find('.__case-study__carousel-slider__slide__description span');
		var description = $(this).find('.owl-item:not(.cloned) .__case-study__carousel-slider__slide').first().attr('title');

		if ($.trim(description).length != 0) {
			descriptionContainer.html(description);
			$(descriptionContainerParent).addClass('--visible');
		} else {
			$(descriptionContainerParent).removeClass('--visible');
		}
	});

	$('.__case-study__carousel-slider').on('translated.owl.carousel', function(event) {
		$(descriptionContainerParent).removeClass('--visible');
		var descriptionContainerParent = $(this).find('.__case-study__carousel-slider__slide__description');
		var descriptionContainer = $(this).find('.__case-study__carousel-slider__slide__description span');
		var description = $(this).find('.owl-item.active .__case-study__carousel-slider__slide').attr('title');

		if ($.trim(description).length != 0) {
			descriptionContainer.html(description);
			$(descriptionContainerParent).addClass('--visible');
		} else {
			$(descriptionContainerParent).removeClass('--visible');
		}
	});
});